<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <!-- <v-card class="pt-2 pb-3"> -->
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="4">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-circle"
                                    clearable
                                    solo
                                    v-model="sales"
                                    :items="saless"
                                    item-value="salesman_id"
                                    item-text="salesman_name"
                                    label="Sales Person"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="4">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    clearable
                                    solo
                                    v-model="customer"
                                    :items="customers"
                                    item-value="cust_id"
                                    item-text="cust_name"
                                    label="Customer"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-border-vertical"
                                    clearable
                                    solo
                                    v-model="outstanding"
                                    :items="outstandings"
                                    item-value="value"
                                    item-text="label"
                                    label="Outstandings Order"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-btn
                                    class="mr-2 rounded-l mt-1"
                                    color="error"
                                    elevation="2"
                                    large
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                        
                    </v-container>
                <!-- </v-card> -->
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #1565C0">
                    <v-card-text class="mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="sales_contracts"
                            :options.sync="options"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            :server-items-length="totalItems"
                            :page.sync="pagination"
                            :search="searchItem"
                            page-count="10"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                            </template>
                            <template v-slot:[`item.wgt_rsv`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_rsv) }}
                            </template>
                            <template v-slot:[`item.wgt_ppp`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_ppp) }}
                            </template>
                            <template v-slot:[`item.wgt_deliv`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_deliv) }}
                            </template>
                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                            </template>
                            <template v-slot:[`item.wgt_stock`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_stock) }}
                            </template>
                            <template v-slot:[`item.wgt_plan`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_plan) }}
                            </template>
                            <template v-slot:[`item.wgt_outstanding`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_outstanding) }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span>Show</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialog" max-width="1200px">                
                    <v-card>
                        <v-card-title>Detail SC</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table :headers="header_po_items" :items="po_items" class="elevation-1" page-count="5" :search="searchDetail" :loading="loading3">
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="d-flex w-25">
                                                        <v-text-field v-model="searchDetail" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                                            </template>
                                            <template v-slot:[`item.wgt_rsv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_rsv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_ppp`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ppp) }}
                                            </template>
                                            <template v-slot:[`item.wgt_deliv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_deliv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                                            </template>
                                            <template v-slot:[`item.wgt_stock`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_stock) }}
                                            </template>
                                            <template v-slot:[`item.wgt_plan`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_plan) }}
                                            </template>
                                            <template v-slot:[`item.wgt_outstanding`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_outstanding) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                breadcumbs: [
                    {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                    },
                    {
                    text: 'Order',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Sales Contract Report (E)',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                loading: false,
                saless: [],
                sales: '',
                customers: [],
                customer: '',
                search: null,
                order_id: '',
                loadingOrder: false,
                items:[],
                outstandings: [
                    {
                        value: 'Y',
                        label: 'Yes'
                    },
                    {
                        value: 'N',
                        label: 'No'
                    }
                ],
                outstanding: '',
                date_from: '',
                modal: false,
                date_to: '',
                modal_to: false,
                headers: [
                    { text: 'Customer', value: 'cust_name' },
                    { text: 'Sales', value: 'salesman_name' },
                    { text: 'Order (Kg)', value: 'wgt_ord' },
                    { text: 'Receive (Kg)', value: 'wgt_rsv' },
                    { text: 'PPP (Kg)', value: 'wgt_ppp' },
                    { text: 'Delivery (Kg)', value: 'wgt_deliv' },
                    { text: 'Ship (Kg)', value: 'wgt_shipped' },
                    { text: 'Stock (Kg)', value: 'wgt_stock' },
                    { text: 'Plan (Kg)', value: 'wgt_plan' },
                    { text: 'Outstanding (Kg)', value: 'wgt_outstanding' },
                    { text: 'Action', value: 'action' }
                ],
                sales_contracts: [],
                options: {},
                totalItems: 10,
                pagination: 1, 
                searchItem: '',
                po_item: {},
                po_items: [],
                dialog: false,
                pagination_item: 1,
                loading3: false,
                totalItem_po_items: 10,
                option_po_items: {},
                header_po_items: [
                    { text: 'Item', value: 'item_num' },
                    { text: 'Order Date', value: 'dt_order' },
                    { text: 'Order ID', value: 'order_id' },
                    { text: 'Descr', value: 'descr' },
                    { text: 'Req Week', value: 'req_ship_week' },
                    { text: 'Order (Kg)', value: 'wgt_ord' },
                    { text: 'Receive (Kg)', value: 'wgt_rsv' },
                    { text: 'PPP (Kg)', value: 'wgt_ppp' },
                    { text: 'Delivery (Kg)', value: 'wgt_deliv' },
                    { text: 'Ship (Kg)', value: 'wgt_shipped' },
                    { text: 'Stock (Kg)', value: 'wgt_stock' },
                    { text: 'Plan (Kg)', value: 'wgt_plan' },
                    { text: 'Outstanding (Kg)', value: 'wgt_outstanding' }
                ],
                searchDetail: ''
            }
        },
        mounted(){
            this.getSales()
            this.getCustomer()
        },
        methods:{
            close(){
                this.dialog = false
            },
            searchItems(){
    
            },
            async getSales(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/sales`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.saless = res.data
                });
            },
            async getCustomer(){
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/CustOrder`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.customers = res.data
                });
            },
            async querySelections (value) {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/sales-contract?search=${value}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.items = res.data
                });
            },
            async submit(page = 1, itemsPerPage = 10){
                this.loading = true
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
                var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
    
                await axios.get(`${process.env.VUE_APP_URL}/api/sm/order_report_ela?txtSalesman=${this.sales ? this.sales : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading = false
                    this.sales_contracts = res.data.data
                    this.totalItems = res.data.total
                    this.pagination = res.data.current_page
                })
            },
            showItem(item){
                console.log(item);
                this.po_item = item
                this.po_items = []
                this.dialog = true
                this.pagination_item = 1
                this.getPullDataDetail()
            },
            async getPullDataDetail(){
                this.loading3 = true

                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
                var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

                await axios.get(`${process.env.VUE_APP_URL}/api/sm/order_report_ela/show?txtSalesman=${this.po_item.salesman_id ? this.po_item.salesman_id : ''}&txtCustomer=${this.po_item.cust_id ? this.po_item.cust_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.po_items = res.data
                    this.loading3 = false
                })
            },
        },
        watch: {
            search (val) {
                val && val !== this.order_id && this.querySelections(val)
            },
            options: {
                handler () {
                    const { page, itemsPerPage } = this.options
                    this.submit(page, itemsPerPage)
                },
            deep: true
            },
        }
    }
    </script>
    